import memoize from 'lodash/memoize';

import get from 'lodash/get';

import { NotificationBar } from 'types';
import sanitizeImage from 'sanitizers/sanitizeImage';

export default memoize((data: unknown): NotificationBar => {
  return {
    disableNotificationBar: get(data, 'disableNotificationBar', true),
    image: sanitizeImage(get(data, 'image')),
    text: get(data, 'text', ''),
    link: {
      url: get(data, 'link.url', ''),
      linkLabel: get(data, 'link.linkLabel', ''),
    },
  };
});
