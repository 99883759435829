import React from 'react';
import Language from 'constants/Language';

import { Img } from 'components/base';

interface Props {
  className?: string;
}

const Loader: React.FC<Props> = ({ className = '' }) => {
  return (
    <Img
      className={className}
      alt={Language.t('Global.loaderAltLabel')}
      src="assets/images/Loader.gif"
    />
  );
};

export default Loader;
