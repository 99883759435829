import memoize from 'lodash/memoize';
import get from 'lodash/get';

import { FacultyMember } from 'types';

export default memoize(
  (facultyMember: unknown): FacultyMember => {
    return {
      id: get(facultyMember, 'id', ''),
      firstName: get(facultyMember, 'firstName', ''),
      lastName: get(facultyMember, 'lastName', ''),
      jobTitle: get(facultyMember, 'jobTitle', ''),
      emailAddress: get(facultyMember, 'emailAddress', ''),
      websiteLinkLabel: get(facultyMember, 'websiteLinkLabel', ''),
      websiteUrl: get(facultyMember, 'websiteUrl', ''),
      department: get(facultyMember, 'department', {}),
      areasOfInterest: get(facultyMember, 'areasOfInterest', ''),
      courses: get(facultyMember, 'courses', ''),
    };
  }
);
