import { RouteComponentProps } from 'react-router-dom';

import ApiClient from 'lib/ApiClient';
import ContainerBase from 'lib/ContainerBase';
import SearchView from 'views/SearchView';
import { SearchPagination } from 'types';

interface StateProps {
  model: SearchPagination | null;
}

type Props = RouteComponentProps & StateProps;

class SearchContainer extends ContainerBase<Props, typeof SearchView> {
  model = (): any => {
    const { pathname } = window.location;

    return ApiClient.fetchRecentResultsFromSearch(pathname.slice(1).split('/')[1], 1);
  };

  view = import('views/SearchView');
}

export default SearchContainer;
