import ImageGroq from './ImageGroq';

export default (fieldName: string) => `
  ${fieldName}[] {
    "id": _key,
    "type": _type,
    "src": asset->url,
    "alt": asset.alt,
    "slides": slides[]{
      "image": image${ImageGroq},
      ...
    },
    ...
  }
`;
