import React, { Component, MouseEvent } from 'react';
import cx from 'classnames';
import Language from 'constants/Language';
import validateEmailAddress from 'utils/validateEmailAddress';

import { Button, TextField } from 'components/base';

interface Props {
  className?: string;
}

interface State {
  emailAddress: string;
  error: string;
}

class NewsletterForm extends Component<Props, State> {
  state = {
    emailAddress: '',
    error: '',
  };

  setEmailAddress = (emailAddress: string): void => {
    this.setState({ emailAddress });
  };

  validate = (): void => {
    if (!validateEmailAddress(this.state.emailAddress)) {
      this.setState({ error: Language.t('NewsletterForm.errorMessage') });
    } else {
      this.setState({ error: '' });
    }
  };

  handleSubmit = (event: MouseEvent) => {
    this.validate();

    if (!!this.state.error) {
      event.preventDefault();
      return;
    }
  };

  render() {
    const { className } = this.props;
    const { error } = this.state;

    return (
      <div className={cx('NewsletterForm col-12 pb2 lg:pb0', className)}>
        <form
          action="https://pratt.us20.list-manage.com/subscribe/post?u=b71111e09c5629dd6e3104c2a&amp;id=735d38f309"
          target="_blank"
          method="post"
        >
          <label className="apercu text-meta-xs color-black">
            {Language.t('NewsletterForm.newsletterText')}
          </label>
          <div className="border-bottom-black pb_25 flex flex-row justify-between">
            <TextField
              variant="primary"
              onBlur={this.validate}
              onChange={(emailAddress) => this.setEmailAddress(emailAddress as string)}
              className="NewsletterForm__input"
              showLabel={true}
              type="email"
              name={Language.t('NewsletterForm.name')}
              ariaLabel={Language.t('Global.emailAddress')}
              placeholder={Language.t('Global.emailAddress')}
              value={this.state.emailAddress}
            />
            <span>
              <Button
                ariaLabel={Language.t('NewsletterForm.subscribeButtonAriaLabel')}
                className="NewsletterForm__button apercu text-meta-xs color-bronze bg-color-transparent"
                onClick={this.handleSubmit}
                type="submit"
              >
                ({Language.t('Global.enter')})
              </Button>
            </span>
          </div>
          {!!error && <div className="pt_5 apercu text-meta-xs color-red">{error}</div>}
        </form>
      </div>
    );
  }
}

export default NewsletterForm;
