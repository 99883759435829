import React, { Component } from 'react';
import cx from 'classnames';
import Language from 'constants/Language';
import sanityImgUtil from 'utils/sanityImgUtil';
import { Img, Button } from 'components/base';

import { NotificationBar as INotificationBar } from 'types';

type Props = {
  data: INotificationBar;
  className?: string;
  notificationMarqueeIsActive: boolean;
  notificationBannerIsActive: boolean;
  toggleNotificationBar: () => void;
};

class NotificationBar extends Component<Props> {
  render() {
    const {
      data,
      className,
      notificationMarqueeIsActive,
      notificationBannerIsActive,
      toggleNotificationBar,
    } = this.props;
    return (
      <>
        <div
          className={cx(
            'NotificationBar none z-overlay md:block md:fixed t0 r0 l0 flex flex-col bg-color-black',
            className,
            {
              'NotificationBar--active': notificationMarqueeIsActive || notificationBannerIsActive,
            }
          )}
        >
          <div
            className={cx('NotificationBar__container flex flex-row relative justify-between', {
              'NotificationBar__container--active': notificationMarqueeIsActive,
            })}
          >
            <div className="NotificationBar__marquee notification-marquee z-overlay flex col-10">
              <div className="NotificationBar__marquee__text apercu z-overlay text-navigation color-white bg-black flex flex-row">
                <div className="flex flex-row pr5">
                  <span className="pr2_5 flex items-center">{data.text}</span>
                  <Button
                    className="NotificationBar__marquee-button link--style-hover-bronze-darker flex items-center bg-color-transparent color-bronze"
                    containerClassName="flex items-center"
                    ariaLabel={Language.t('NotificationBar.hideButtonAriaLabel')}
                    to={data.link.url}
                    openInNewTab={true}
                  >
                    {data.link.linkLabel}
                  </Button>
                </div>
                <div className="flex flex-row">
                  <span className="pr2_5 flex items-center">{data.text}</span>
                  <Button
                    className="flex items-center bg-color-transparent color-bronze"
                    containerClassName="flex items-center"
                    ariaLabel={Language.t('NotificationBar.hideButtonAriaLabel')}
                    to={data.link.url}
                  >
                    {data.link.linkLabel}
                  </Button>
                </div>
              </div>
            </div>
            <div className="NotificationBar__gradient z-overlay flex flex-col col-2 items-end justify-center pr_4 md:pr_75">
              <Button
                className="NotificationBar__hide-button apercu text-navigation items-center bg-color-transparent color-white"
                ariaLabel={Language.t('NotificationBar.hideButtonAriaLabel')}
                onClick={toggleNotificationBar}
              >
                {Language.t('NotificationBar.hide')}
              </Button>
            </div>
          </div>
          {!!data.image.src && (
            <div
              className={cx(
                'NotificationBar__banner transition-medium site-margin-x z-notificationBanner grid-white bg-color-black flex justify-center items-center border-right-white opacity-0',
                {
                  'NotificationBar__banner--active opacity-1': notificationBannerIsActive,
                }
              )}
            >
              <div
                className={cx('NotificationBar__image-container h100 flex col-2 mxauto pb1_5', {
                  'events-none': !notificationBannerIsActive,
                })}
              ></div>
              <Button
                className={cx(
                  'NotificationBar__sticker transition-shortest none md:block fixed z-overlay',
                  {
                    'events-none': !notificationBannerIsActive,
                  }
                )}
                ariaLabel={data.link.linkLabel}
                to={data.link.url}
              >
                <Img
                  className="h100 w100 fit-contain"
                  src={sanityImgUtil(data.image, 500)}
                  alt={data.image.alt}
                />
              </Button>
            </div>
          )}
        </div>
      </>
    );
  }
}

export default NotificationBar;
