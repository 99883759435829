import memoize from 'lodash/memoize';
import get from 'lodash/get';
import { Campuses } from 'types';

import sanitizeLink from 'sanitizers/sanitizeLink';

export default memoize((data: unknown): Campuses => {
  return {
    locations: get(data, 'locations', []).map((location: unknown) => {
      return {
        name: get(location, 'name', ''),
        buildingName: get(location, 'buildingName', ''),
        address: get(location, 'address', ''),
        city: get(location, 'city', ''),
        state: get(location, 'state', ''),
        zip: get(location, 'zip', ''),
        directionsLink: sanitizeLink(get(location, 'directionsLink', '')),
        visitLink: sanitizeLink(get(location, 'visitLink', '')),
      };
    }), 
  };
});
