import React from 'react';
import { Route, Switch } from 'react-router-dom';

import GenericPageContainer from 'containers/GenericPageContainer';
import SearchContainer from 'containers/SearchContainer';
import { RouteMap } from 'constants/RouteMap';

const Routes: React.FC = () => {
  
  return (
    <Switch>
      {Object.values(RouteMap).map(route => (
        <Route
          key={route.path}
          path={route.path}
          exact={route.exact}
          component={route.component}
        />
      ))}
      <Route exact path='/search' component={SearchContainer} />
      <Route exact path='/' component={GenericPageContainer} />
      <Route exact path='/:genericPageSlug' component={GenericPageContainer} />
    </Switch>
  );
};

export default Routes;
