import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { render, hydrate } from 'react-dom';
import App from './App';

Sentry.init({
  dsn: 'https://9b1c21babb4d4ae282943874d78b9639@o469181.ingest.sentry.io/5498144',
  integrations: [new Integrations.BrowserTracing()],

  tracesSampleRate: 1.0,
});

const rootElement = document.getElementById('root');
if (rootElement && rootElement.hasChildNodes()) {
  hydrate(<App />, rootElement);
} else {
  render(<App />, rootElement);
}
