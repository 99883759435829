import ImageGroq from './ImageGroq';

export default `{
  "id": _id,
  "notificationBar": {
    "disableNotificationBar": disableNotificationBar,
    "image": notificationBarImage${ImageGroq},
    "link": notificationBarLink,
    "text": notificationBarText
  },
  "navigationMenu": {
    "departments": departments,
    "additionalLinks": additionalLinks,
    "socialMediaLinks": socialMediaLinks,
    "campuses": campuses,
    "generalFooterMenuLinks": generalFooterMenuLinks,
  },
  "pageNotFoundModel": pageNotFoundModel{"id": _key, "src": asset->url},
  "globalDefaultImages": globalDefaultImages[]${ImageGroq},
  "cookieConsent": {"text": cookieConsentText.text, "link": cookieConsentLink},
...
}`;
