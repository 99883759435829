import memoize from 'lodash/memoize';
import get from 'lodash/get';

import { GenericPage } from 'types';
import sanitizeSeoSettings from './sanitizeSeoSettings';
import sanitizeTopics from 'sanitizers/sanitizeTopics';

export default memoize((genericPage: unknown): GenericPage | null => {
  const id = get(genericPage, '_id');

  if (!id) {
    return null;
  }

  return {
    id,
    title: get(genericPage, 'title', ''),
    slug: get(genericPage, 'slug'),
    blocks: get(genericPage, 'blocks', []),
    seo: sanitizeSeoSettings(get(genericPage, 'seo')),
    globalSettings: get(genericPage, 'globalSettings', {}),
    topics: sanitizeTopics(get(genericPage, 'topics', []))
  };
});
