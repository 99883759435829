import memoize from 'lodash/memoize';

import get from 'lodash/get';

import { RelatedLinks } from 'types';
import sanitizeLink from './sanitizeLink';

export default memoize((data: unknown): RelatedLinks => {

  return {
    type: 'articleRelatedLinks',
    title: get(data, 'title'),
    links: get(data, 'links', []).map((link: unknown) => {
      return sanitizeLink(link);
    }),
  };
});
