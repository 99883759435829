import ImageGroq from './ImageGroq';

export default `{
  "id": _id,
  title,
  briefTitle,
  slug,
  authorsInfo,
  'heroImage': heroImage${ImageGroq},
  'thumbnailImage': thumbnailImage${ImageGroq},
  "model": model{"id": _key, "src": asset->url},
  "createdAt": _createdAt,
  topics[]->{ title, slug }
}`;
