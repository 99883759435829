import memoize from 'lodash/memoize';

import get from 'lodash/get';

import { SeoSettings } from 'types';
import sanitizeImage from 'sanitizers/sanitizeImage';

export default memoize(
  (data: unknown): SeoSettings => {
    return {
      title: get(data, 'title', ''),
      description: get(data, 'description', ''),
      image: sanitizeImage(get(data, 'image', {})),
    };
  }
);
