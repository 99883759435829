import memoize from 'lodash/memoize';
import get from 'lodash/get';

import { Image } from 'types';

export default memoize(
  (image: unknown): Image => {
    const id = get(image, 'id', '');

    return {
      id,
      src: get(image, 'src', ''),
      alt: get(image, 'alt', ''),
      caption: get(image, 'caption', ''),
      crop: get(image, 'crop', null),
      hotspot: get(image, 'hotspot', null),
      metadata: get(image, 'metadata', null),
    };
  }
);
