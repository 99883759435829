import memoize from 'lodash/memoize';
import get from 'lodash/get';

import sanitizeLink from 'sanitizers/sanitizeLink';

import { NavigationMenuLinks } from 'types';

export default memoize((data: unknown): NavigationMenuLinks => {

  return {
    title: get(data, 'title', ''),
    links: get(data, 'links', []).map((link: unknown) => {
      return sanitizeLink(link);
    }),  
  }
});
