import memoize from 'lodash/memoize';
import get from 'lodash/get';

import { TopicsPage } from 'types';
import sanitizeSeoSettings from './sanitizeSeoSettings';
import sanitizeImage from 'sanitizers/sanitizeImage';
import sanitizeArticleLink from 'sanitizers/sanitizeArticleLink';
import articlesByDate from 'sanitizers/articlesByDate';

export default memoize((topicsPage: unknown): TopicsPage | null => {
  const id = get(topicsPage, '_id');
  const heroId = get(topicsPage, 'hero.heroImage.id') || get(topicsPage, 'hero.model.id');

  if (!id) {
    return null;
  }

  return {
    id,
    title: get(topicsPage, 'title', ''),
    slug: get(topicsPage, 'slug'),
    seo: sanitizeSeoSettings(topicsPage),
    hero: {
      id: heroId,
      type: 'heroBlock',
      heroImage: sanitizeImage(get(topicsPage, 'hero.heroImage')),
      model: get(topicsPage, 'hero.model')
    },
    description: get(topicsPage, 'description', []),
    articles: articlesByDate(get(topicsPage, 'articles', []).map((article: unknown) => {
      return sanitizeArticleLink(article);
    }))
  };
});
