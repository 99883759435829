export default `{
  "id": _id,
  "type": _type,
  "firstName": firstName,
  "lastName": lastName,
  "jobTitle": jobTitle,
  "emailAddress": emailAddress,
  "websiteLinkLabel": websiteLinkLabel,
  "websiteUrl": websiteUrl,
  "department": department->{"id": _id, "name": name},
  "courses": courses,
  ...
}`;
