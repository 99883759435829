import memoize from 'lodash/memoize';
import get from 'lodash/get';
import { Article } from 'types';
import sanitizeArticleRelatedLinksBlock from './sanitizeArticleRelatedLinksBlock';
import sanitizeStudentWorkBlock from './sanitizeStudentWorkBlock';
import sanitizeSeoSettings from 'sanitizers/sanitizeSeoSettings';
import sanitizeImage from 'sanitizers/sanitizeImage';
import sanitizeTopics from 'sanitizers/sanitizeTopics';
import sanitizeGalleryBlock from 'sanitizers/sanitizeGalleryBlock';

export default memoize((article: unknown): Article | null => {  
  const id = get(article, '_id') || get(article, 'id');

  if (!id) {
    return null;
  }

  return {
    id,
    title: get(article, 'title', ''),
    date: get(article, '_createdAt'),
    briefTitle: get(article, 'briefTitle', ''),
    subheading: get(article, 'subheading', ''),
    slug: get(article, 'slug', ''),
    meta: {
      authorsInfo: {
        authors: get(article, 'meta.authorsInfo.authors', []),
        details: get(article, 'meta.authorsInfo.details', ''),
      },
      date:
        get(article, 'date') ||
        get(article, '_updatedAt') ||
        get(article, '_createdAt') ||
        '',
      topics: sanitizeTopics(get(article, 'meta.topics', []))
    },
    seo: sanitizeSeoSettings(get(article, 'seo', {})),
    hero: {
      heroImage: sanitizeImage(get(article, 'hero.heroImage')),
      model: get(article, 'hero.model', '')
    },
    content: {
      body: get(article, 'content.body', []),
    },
    relatedLinks: sanitizeArticleRelatedLinksBlock(get(article, 'relatedLinks', {})),
    studentWorkBlock: sanitizeStudentWorkBlock(get(article, 'studentWorkBlock', {})),
    authorsReferences: get(article, 'authorsReferences', []),
    galleryBlock: sanitizeGalleryBlock(get(article, 'galleryBlock', {})),
  };
});
