import memoize from 'lodash/memoize';
import get from 'lodash/get';

import sanitizeImage from 'sanitizers/sanitizeImage';
import { TopicLink } from 'types';

export default memoize((topic: unknown): TopicLink => {
  return {
    id: get(topic, 'id'),
    title: get(topic, 'title', ''),
    slug: get(topic, 'slug', ''),
    heroImage: sanitizeImage(get(topic, 'heroImage', ''))
  };
});
