import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import { Button } from 'components/base';

interface Props {
  button: React.ReactNode;
  content: React.ReactNode;
  containerClassName?: string;
  dismissButtonAriaLabel: string;
  dismissButtonClassName?: string;
  dismissButtonLabel: string;
}

const setCookie = (cookieKey: string, cookieValue: number) => {
  document.cookie = `${cookieKey}=${cookieValue}; path=/`;
};

const CookieConsent: React.FC<Props> = ({
  button,
  content,
  containerClassName,
  dismissButtonAriaLabel,
  dismissButtonClassName,
  dismissButtonLabel,
}) => {
  const [isVisible, setIsVisible] = useState(false);

  const handleCookieConsent = () => {
    const cookieName = 'consentedToCookiesAt';
    const match = document?.cookie.match(RegExp('(?:^|;\\s*)' + cookieName + '=([^;]*)'));

    //If the cookie has already been set, hide the cookie consent pop up.
    //If it hasn't been set, show the pop up.
    match && match[1] ? setIsVisible(false) : setIsVisible(true);
  };

  const setCookieAndDismissPopUp = () => {
    setCookie('consentedToCookiesAt', Date.now());
    setIsVisible(false);
  };

  useEffect(() => {
    handleCookieConsent();
  }, []);

  return (
    <div
      className={cx(
        'CookieConsent flex flex-col fixed z-nav justify-center transition',
        containerClassName,
        {
          'opacity-0 events-none': !isVisible,
          'opacity-1 events-all': isVisible,
        }
      )}
    >
      {content}
      {button}
      <Button
        ariaLabel={dismissButtonAriaLabel}
        className={dismissButtonClassName}
        label={dismissButtonLabel}
        onClick={setCookieAndDismissPopUp}
      />
    </div>
  );
};

export default CookieConsent;
