import memoize from 'lodash/memoize';

import get from 'lodash/get';

import { Link } from 'types';

export default memoize((data: unknown): Link => {

  return {
    linkLabel: get(data, 'linkLabel', ''),
    url: get(data, 'url', ''),
  };
});
