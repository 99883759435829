import memoize from 'lodash/memoize';
import { compareDesc } from 'date-fns';

import { ArticleLink } from 'types';

export default memoize((articles: ArticleLink[]): ArticleLink[] => {

  return articles
    .filter((article: ArticleLink) => {
      return compareDesc(new Date(article.date), new Date()) > 0;
    })
    .sort((articleA: ArticleLink, articleB: ArticleLink) =>
      compareDesc(new Date(articleA.date), new Date(articleB.date))
    );
});
