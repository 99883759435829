import React from 'react';
import cx from 'classnames';
import { Button } from 'components/base';
import { NavigationMenuLinks, Link, LinkListVariantType, BodyLinks } from 'types';

interface Props {
  className?: string;
  data: NavigationMenuLinks | BodyLinks | null;
  variant: LinkListVariantType;
  toggleMainMenu?: () => void;
}

const LinkList: React.FC<Props> = ({ data, className, variant, toggleMainMenu }) => {
  if (!data) {
    return null;
  }

  const hasNavStyling = variant === LinkListVariantType.NAV;
  const hasBodyStyling = variant === LinkListVariantType.BODY;
  const hasArticleSidebarStyling = variant === LinkListVariantType.ARTICLE_SIDEBAR;

  return (
    <div
      className={cx('LinkList apercu flex', className, {
        'text-navigation-link': hasNavStyling || hasArticleSidebarStyling,
        'flex-col': hasNavStyling || hasBodyStyling,
        'LinkList--style-article xl-lg:p0 flex-col': hasArticleSidebarStyling,
        'pb1_75 md:pb2_5': hasBodyStyling,
      })}
    >
      <div
        className={cx({
          'LinkList__link-title': hasBodyStyling,
          'LinkList__link-title LinkList--style-article__link-title flex col-6 lg:col-12 color-green pb1': hasArticleSidebarStyling,
          'LinkList--style-nav__link-title': hasNavStyling,
        })}
      >
        {data.title}
      </div>
      {data && data.links && (
        <div
          className={cx({
            'col-12': hasArticleSidebarStyling,
            LinkList__link: hasBodyStyling,
          })}
        >
          {data.links.map((link: Link) => (
            <div
              className={cx('LinkList__links-container', {
                'flex flex-col col-12': hasArticleSidebarStyling,
              })}
              key={link.url}
            >
              <Button
                className={cx(
                  'LinkList__link apercu bg-color-transparent color-bronze transition-shortest',
                  {
                    'text-navigation-link': hasNavStyling,
                  }
                )}
                ariaLabel={link.linkLabel || ''}
                to={link.url}
                onClick={hasNavStyling ? toggleMainMenu : undefined}
              >
                <span>{link.linkLabel}</span>
              </Button>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default LinkList;
