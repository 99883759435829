import imageUrlBuilder from "@sanity/image-url";

import { Image } from "types";

export type SanityDataset = "production";
const dataset: SanityDataset = "production";
const ProjectId = '9t101670';

export default (
  image: Image,
  width: number,
  quality: number,
  dpr: number
): string => {
  if (!ProjectId) {
    console.error("Sanity project id is missing");

    return "";
  }

  const builder = imageUrlBuilder({
    projectId: ProjectId,
    dataset: dataset,
  });

  const imageAsImageObject = {
    asset: {
      _ref: image.id,
    },
    crop: image.crop,
    hotspot: image.hotspot,
  };

  return (
    builder
      .image(imageAsImageObject)
      .width(width)
      .quality(quality)
      .dpr(dpr)
      .auto("format")
      .url() || ""
  );
};
