import memoize from 'lodash/memoize';
import get from 'lodash/get';

import { GlobalSettings } from 'types';
import sanitizeNotificationBar from 'sanitizers/sanitizeNotificationBar';
import sanitizeNavigationMenu from 'sanitizers/sanitizeNavigationMenu';

export default memoize(
  (globalSettings: unknown): GlobalSettings => {
    return {
      notificationBar: sanitizeNotificationBar(get(globalSettings, 'notificationBar', '')),
      navigationMenu: sanitizeNavigationMenu(get(globalSettings, 'navigationMenu', '')),
      pageNotFoundModel: get(globalSettings, 'pageNotFoundModel', ''),
      cookieConsent: get(globalSettings, 'cookieConsent', ''),
    };
  }
);
