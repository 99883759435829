import ImageGroq from './ImageGroq';
import ArticleLinkGroq from './ArticleLinkGroq';
import FacultyMemberGroq from './FacultyMemberGroq';
import SocialMediaImageGroq from './SocialMediaImageGroq';

export default `{ 
  "id": _key,
  "type": _type,
  "heroImage": heroImage${ImageGroq},
  "article": article->${ArticleLinkGroq},
  "articles": articles[]->${ArticleLinkGroq},
  "model": model{"id": _key, "src": asset->url},
  "images": images[]${ImageGroq},
  "image": image${ImageGroq},
  "socialMediaImages": socialMediaImages[]${SocialMediaImageGroq},
  "facultyMembers": facultyMembers[]->${FacultyMemberGroq} | order(lastName),
  "featuredTopics": featuredTopics[]->{ "id": _id, title, slug, heroImage${ImageGroq} },
  "articleHeroImage": image${ImageGroq},
  thumbnailImage${ImageGroq},
  listOfTopics[]->{ "id": _id, title, slug, heroImage${ImageGroq} },
  ... 
}`;
