import React, { Component, ComponentType } from 'react';

import { Subtract } from 'utility-types';

export type CurrentBreakpoint = string | null;

export interface InjectedProps {
  currentBreakpoint: string;
}

export interface Breakpoint {
  label: string;
  lowerBound: number;
  upperBound: number;
}

export interface State {
  currentBreakpoint: CurrentBreakpoint;
}

export const Breakpoints: {
  [id: string]: Breakpoint;
} = {
  EXTRA_SMALL: {
    label: 'EXTRA_SMALL',
    lowerBound: 0,
    upperBound: 375
  },
  SMALL_EXTRA_SMALL: {
    label: 'SMALL_EXTRA_SMALL',
    lowerBound: 375,
    upperBound: 500
  },
  SMALL: {
    label: 'SMALL',
    lowerBound: 500,
    upperBound: 640
  },
  MEDIUM: {
    label: 'MEDIUM',
    lowerBound: 640,
    upperBound: 768
  },
  LARGE_MEDIUM: {
    label: 'LARGE_MEDIUM',
    lowerBound: 768,
    upperBound: 900
  },
  LARGE: {
    label: 'LARGE',
    lowerBound: 900,
    upperBound: 1080
  },
  EXTRA_LARGE_LARGE: {
    label: 'EXTRA_LARGE_LARGE',
    lowerBound: 1080,
    upperBound: 1180
  },
  EXTRA_LARGE: {
    label: 'EXTRA_LARGE',
    lowerBound: 1180,
    upperBound: 1280
  },
  EXTRA_EXTRA_LARGE: {
    label: 'EXTRA_EXTRA_LARGE',
    lowerBound: 1280,
    upperBound: 1440
  },
  EXTRA_EXTRA_EXTRA_LARGE: {
    label: 'EXTRA_EXTRA_EXTRA_LARGE',
    lowerBound: 1440,
    upperBound: 1600,
  },
  EXTRA_EXTRA_EXTRA_EXTRA_LARGE: {
    label: 'EXTRA_EXTRA_EXTRA_EXTRA_LARGE',
    lowerBound: 1600,
    upperBound: 1000000
  },
};

const withBreakpoints = <WrappedComponentProps extends InjectedProps>(
  WrappedComponent: ComponentType<WrappedComponentProps>
) => {
  class WithBreakpoints extends Component<
    Subtract<WrappedComponentProps, InjectedProps>,
    State
  > {
    constructor(props: WrappedComponentProps) {
      super(props);

      this.state = {
        currentBreakpoint: this.getCurrentBreakpoint()
      };
    }

    componentDidMount() {
      window.addEventListener('resize', this.checkBreakpoints);
    }

    componentWillUnmount() {
      window.removeEventListener('resize', this.checkBreakpoints);
    }

    checkBreakpoints = () => {
      let currentBreakpoint: CurrentBreakpoint = this.getCurrentBreakpoint();

      if (currentBreakpoint !== this.state.currentBreakpoint) {
        this.setState({ currentBreakpoint });
      }
    };

    getCurrentBreakpoint = (): CurrentBreakpoint => {
      const currentViewportWidth: number = Math.round(window.innerWidth);

      return (
        Object.keys(Breakpoints).find(
          key =>
            Breakpoints[key].lowerBound <= currentViewportWidth &&
            Breakpoints[key].upperBound > currentViewportWidth
        ) || null
      );
    };

    render() {
      return (
        <WrappedComponent
          {...(this.props as WrappedComponentProps)}
          currentBreakpoint={this.state.currentBreakpoint}
        />
      );
    }
  }

  return WithBreakpoints;
};

export default withBreakpoints;
