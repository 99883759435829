export default `{ 
  'src': asset->url, 
  hotspot, 
  alt, 
  caption, 
  asset, 
  crop, 
  'id': asset->_id,  
  "metadata": asset->metadata{
    dimensions 
  } 
}`;
