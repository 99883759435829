import memoize from 'lodash/memoize';

import sanitizeImage from 'sanitizers/sanitizeImage';

import { Image } from 'types';

export default memoize((images: unknown[]): Image[] => {

  return images.map(sanitizeImage);
});
