import React, { Component } from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import get from 'lodash/get';
import cx from 'classnames';
import Language from 'constants/Language';
import { RouteMap } from 'constants/RouteMap';
import withBreakpoints, {
  Breakpoints,
  InjectedProps as WithBreakpointsProps,
} from 'lib/withBreakpoints';

import SecondaryMenuLinks from 'constants/SecondaryMenu';
import { Img, Button, LinkList } from 'components/base';
import NavFooterMenu from 'components/NavFooterMenu';
import NotificationBar from 'components/NotificationBar';
import { Link as ILink, LinkListVariantType, GlobalSettings } from 'types';
import isCurrentPathname from 'utils/isCurrentPathname';

type OwnProps = {
  globalSettings: GlobalSettings | null;
  toggleNotificationBar: () => void;
  handleMainMenuClick: () => void;
  toggleMainMenu: () => void;
  notificationMarqueeIsActive: boolean;
  notificationBannerIsActive: boolean;
  notificationBarIsHidden: boolean;
  mainMenuIsActive: boolean;
  secondaryNavIsActive: boolean;
  shouldShowBottomBorder: boolean;
  hasSticker: boolean;
};

type Props = OwnProps & RouteComponentProps & WithBreakpointsProps;

class Nav extends Component<Props> {
  render() {
    const {
      notificationBannerIsActive,
      notificationMarqueeIsActive,
      toggleNotificationBar,
      toggleMainMenu,
      handleMainMenuClick,
      notificationBarIsHidden,
      mainMenuIsActive,
      secondaryNavIsActive,
      currentBreakpoint,
      shouldShowBottomBorder,
      hasSticker,
    } = this.props;

    const breakpointIsMdDown = [
      Breakpoints.MEDIUM.label,
      Breakpoints.SMALL.label,
      Breakpoints.SMALL_EXTRA_SMALL.label,
      Breakpoints.EXTRA_SMALL.label,
    ].includes(currentBreakpoint);

    const breakpointIsMobile = [
      Breakpoints.SMALL_EXTRA_SMALL.label,
      Breakpoints.EXTRA_SMALL.label,
    ].includes(currentBreakpoint);

    const globalSettings = get(this, 'props.globalSettings');

    if (!globalSettings) {
      return null;
    }

    return (
      <div>
        {!notificationBarIsHidden && (
          <NotificationBar
            data={globalSettings.notificationBar}
            notificationMarqueeIsActive={notificationMarqueeIsActive}
            notificationBannerIsActive={notificationBannerIsActive}
            toggleNotificationBar={toggleNotificationBar}
          />
        )}
        <div
          className={cx('Nav fixed z-nav r0 l0 transition-shorter', {
            'Nav--menu-active overflow-y-scroll': mainMenuIsActive,
            'Nav--full-notification-active': !notificationBarIsHidden && hasSticker,
            'Nav--notification-marquee-active':
              (notificationMarqueeIsActive &&
                !notificationBannerIsActive &&
                !notificationBarIsHidden) ||
              (!notificationBarIsHidden && !hasSticker),
            'bg-color-white': breakpointIsMobile,
          })}
        >
          <div
            className={cx(
              'Nav__primary-nav primary-nav z-nav flex flex-col justify-center bg-color-white events-all',
              {
                'Nav__primary-nav--menu-active sticky t0 r0 l0 site-padding-x': mainMenuIsActive,
                'site-margin-x relative': !mainMenuIsActive,
                'border-bottom-black': !secondaryNavIsActive && !mainMenuIsActive,
                'Nav__primary-nav--secondary-nav-active':
                  secondaryNavIsActive && !mainMenuIsActive && !breakpointIsMdDown,
                'justify-between': mainMenuIsActive && !breakpointIsMdDown,
              }
            )}
          >
            <div
              className={cx('Nav__primary-nav-content flex flex-row justify-between', {
                'Nav__primary-nav-content--menu-active': mainMenuIsActive,
              })}
            >
              <div className="flex col-7 sm:col-8 apercu text-navigation color-black items-center">
                <Button
                  className="sm:flex items-center color-black bg-color-transparent text-decoration-none"
                  ariaLabel={Language.t('Global.prattSchoolOfArchitecture')}
                  to={RouteMap.HOME.path}
                >
                  <Img
                    className="Nav__logo-container left sm:mt0 mr_5 xl-lg:mr1_25 flex items-center"
                    src="/assets/images/pratt-logo.svg"
                    alt="Pratt Logo"
                  />
                  <span className="Nav__school-of-architecture-text nav-line-height-full-crop w100">
                    {Language.t('Global.schoolOfArchitecture')}
                  </span>
                </Button>
              </div>
              <Button
                className="Nav__menu-button link--style-hover-bronze-darker nav-line-height-full-crop transition-shortest flex flex-col col-4 apercu text-navigation justify-start items-end bg-color-transparent color-bronze sm:none"
                ariaLabel={
                  mainMenuIsActive
                    ? Language.t('Nav.closeButtonAriaLabel')
                    : Language.t('Nav.menuButtonAriaLabel')
                }
                onClick={toggleMainMenu}
              >
                {mainMenuIsActive ? Language.t('Nav.close') : Language.t('Nav.menu')}
              </Button>
              <Button
                className="Nav__menu-button link--style-hover-bronze-darker transition-shortest flex flex-col apercu text-navigation justify-center items-end bg-color-transparent none sm:block sm:py_5 xl-lg:py_625 color-bronze sm:flex sm:justify-center"
                ariaLabel={
                  mainMenuIsActive
                    ? Language.t('Nav.closeButtonAriaLabel')
                    : Language.t('Nav.menuButtonAriaLabel')
                }
                onClick={toggleMainMenu}
              >
                {mainMenuIsActive ? Language.t('Nav.close') : Language.t('Nav.menu')}
              </Button>
            </div>
            {mainMenuIsActive && (
              <div className="Nav__menu-secondary-nav w100 none md:flex flex-row flex-wrap items-center bg-color-white">
                {SecondaryMenuLinks &&
                  SecondaryMenuLinks.map((link: ILink, index: number) => (
                    <div key={link.linkLabel}>
                      <Link
                        to={link.url}
                        className={cx(
                          'Nav__button Nav__menu-secondary-nav-button transition-shortest apercu text-decoration-none text-navigation color-bronze mr_25',
                          {
                            italic: isCurrentPathname(this.props.location.pathname, link.linkLabel),
                          }
                        )}
                        aria-label={link.linkLabel}
                        onClick={handleMainMenuClick}
                      >
                        {link.linkLabel}
                        {index !== SecondaryMenuLinks.length - 1 && (
                          <span className="mr_25">{Language.t('Global.comma')}</span>
                        )}
                      </Link>
                    </div>
                  ))}
              </div>
            )}
          </div>
          {!mainMenuIsActive && (
            <div
              className={cx(
                'Nav__secondary-nav w100 secondary-nav none md:flex items-center flex-row flex-wrap bg-color-white transition-shortest site-padding-x',
                {
                  'Nav__secondary-nav--closed events-none': !secondaryNavIsActive,
                  'events-all': secondaryNavIsActive,
                }
              )}
            >
              {SecondaryMenuLinks &&
                SecondaryMenuLinks.map((link: ILink, index: number) => (
                  <div className="Nav__button-container h100" key={link.linkLabel}>
                    <Link
                      to={link.url}
                      className={cx(
                        'Nav__button transition-shortest apercu text-decoration-none text-navigation color-bronze mr_25',
                        {
                          italic: isCurrentPathname(this.props.location.pathname, link.linkLabel),
                        }
                      )}
                      aria-label={link.linkLabel}
                      onClick={mainMenuIsActive ? toggleMainMenu : () => {}}
                    >
                      {link.linkLabel}
                      {index !== SecondaryMenuLinks.length - 1 && (
                        <span className="mr_25">{Language.t('Global.comma')}</span>
                      )}
                    </Link>
                  </div>
                ))}
            </div>
          )}
          <div
            className={cx(
              'Nav__main-menu main-menu w100 bg-color-white flex flex-col site-padding-x',
              {
                'Nav__main-menu--closed opacity-0 events-none': !mainMenuIsActive,
                'events-all transition-shorter z-nav': mainMenuIsActive,
              }
            )}
          >
            <div className="Nav__main-menu-inner-container flex flex-col lg:flex-row justify-between md:py1">
              <div className="Nav__secondary-nav--mobile flex flex-row flex-wrap md:none border-bottom-black">
                {SecondaryMenuLinks &&
                  SecondaryMenuLinks.map((link: ILink, index: number) => (
                    <Link
                      key={link.linkLabel}
                      to={link.url}
                      className="Nav__button Nav__secondary-nav-mobile-button transition-shortest apercu text-decoration-none text-navigation color-bronze mr_25"
                      aria-label={link.linkLabel || ''}
                      onClick={handleMainMenuClick}
                    >
                      {link.linkLabel}
                      {index !== SecondaryMenuLinks.length - 1 && (
                        <span className="mr_25">{Language.t('Global.comma')}</span>
                      )}
                    </Link>
                  ))}
              </div>
              {globalSettings.navigationMenu.departments && (
                <div className="lg:col-7 pb2 lg:pb0 lg:pr2">
                  <LinkList
                    key={globalSettings.navigationMenu.departments.title}
                    variant={LinkListVariantType.NAV}
                    toggleMainMenu={toggleMainMenu}
                    data={globalSettings.navigationMenu.departments}
                  />
                </div>
              )}
              {globalSettings.navigationMenu.additionalLinks.links && (
                <div className="lg:col-5">
                  <LinkList
                    toggleMainMenu={toggleMainMenu}
                    variant={LinkListVariantType.NAV}
                    data={globalSettings.navigationMenu.additionalLinks}
                  />
                </div>
              )}
            </div>
            <NavFooterMenu
              generalLinks={globalSettings.navigationMenu.generalFooterMenuLinks}
              campuses={globalSettings.navigationMenu.campuses}
              socialMediaLinks={globalSettings.navigationMenu.socialMediaLinks}
              shouldShowBottomBorder={shouldShowBottomBorder}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default withBreakpoints(withRouter(Nav));
