import { Link } from 'types';

const SecondaryMenuLinks: Link[] = [
  {
    url: '/research',
    linkLabel: 'Research',
  },
  {
    url: '/programs',
    linkLabel: 'Programs',
  },
  {
    url: '/culture',
    linkLabel: 'Culture',
  },
  {
    url: '/information',
    linkLabel: 'Information',
  },
  {
    url: '/search',
    linkLabel: 'Search',
  }
];

export default SecondaryMenuLinks;
