import memoize from 'lodash/memoize';

import get from 'lodash/get';

import sanitizeNavigationMenuLinks from 'sanitizers/sanitizeNavigationMenuLinks';
import sanitizeCampuses from 'sanitizers/sanitizeCampuses';

import { NavigationMenu } from 'types';

export default memoize((data: unknown): NavigationMenu => {
  return {
    departments: sanitizeNavigationMenuLinks(get(data, 'departments', {})),
    additionalLinks: sanitizeNavigationMenuLinks(get(data, 'additionalLinks', {})),
    socialMediaLinks: sanitizeNavigationMenuLinks(get(data, 'socialMediaLinks', {})),
    campuses: sanitizeCampuses(get(data, 'campuses', {})),
    generalFooterMenuLinks: sanitizeNavigationMenuLinks(get(data, 'generalFooterMenuLinks', {}))
  };
});
