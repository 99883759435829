import React from 'react';

import { Img } from 'components/base';

interface Props {
  className: string;
  alt: string;
  src: string;
}

const ImgWithHover: React.FC<Props> = ({ className, src, alt }) => {
  const cells = Array.from(Array(100), (_, i) => i + 1);

  return (
    <div className="ImgWithHover__reflection-container">
      {cells.map((value: number) => {
        return (
          <div
            key={`ImgWithHover__grid-cell-${value}`}
            className={`ImgWithHover__reflection-grid-cell ImgWithHover__reflection-grid-cell-${value}`}
          ></div>
        );
      })}
      <div className="ImgWithHover__reflection-content image-drop-shadow">
        <Img className={className} src={src} alt={alt} />
      </div>
    </div>
  );
};

export default ImgWithHover;
