import memoize from 'lodash/memoize';
import get from 'lodash/get';
import { BlockGallery } from 'types';

import sanitizeArticleLinkResponse from 'sanitizers/sanitizeArticleLinkResponse';

export default memoize((data: unknown): BlockGallery => {
  return {
    type: 'galleryBlock',
    id: (get(data, 'articles[0].id')),
    header: (get(data, 'header', '')),
    articles: sanitizeArticleLinkResponse(get(data, 'articles', []))
  };
});
