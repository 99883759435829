import React, { Component, createRef, RefObject } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import 'styles/App.scss';
import get from 'lodash/get';
import throttle from 'lodash/throttle';
import Language from 'constants/Language';
import cx from 'classnames';
import withBreakpoints, {
  Breakpoints,
  InjectedProps as WithBreakpointsProps,
} from 'lib/withBreakpoints';
import { GlobalSettings } from 'types';
import ApiClient from 'lib/ApiClient';
import { Button } from 'components/base';
import Nav from 'components/Nav';
import Routes from 'constants/routes';
import Loader from 'components/Loader';
import ScrollToTop from 'components/ScrollToTop';
import CookieConsent from 'components/CookieConsent';

interface State {
  initializeApplicationFulfilled: boolean;
  secondaryNavIsActive: boolean;
  notificationMarqueeIsActive: boolean;
  notificationBannerIsActive: boolean;
  hasSticker: boolean;
  notificationBarIsHidden: boolean;
  mainMenuIsActive: boolean;
  globalSettings: GlobalSettings | null;
  shouldShowBottomBorder: boolean;
  navNotificationBarHeight: number;
}

type Props = WithBreakpointsProps;

class App extends Component<Props, State> {
  navContainerRef: RefObject<HTMLDivElement> = createRef();

  constructor(props: Props) {
    super(props);

    this.state = {
      initializeApplicationFulfilled: false,
      secondaryNavIsActive: false,
      notificationMarqueeIsActive: false,
      notificationBannerIsActive: false,
      hasSticker: false,
      notificationBarIsHidden: false,
      mainMenuIsActive: false,
      globalSettings: null,
      shouldShowBottomBorder: false,
      navNotificationBarHeight: 0,
    };
  }

  componentDidMount() {
    if (!this.state.initializeApplicationFulfilled) {
      this.intializeApplication();
    }

    this.handleScroll();

    const pageOffset =
      get(window, 'pageYOffset', 0) || get(document, 'documentElement.scrollTop', 0);

    if (pageOffset === 0) {
      this.setState({
        notificationBannerIsActive: true,
        notificationMarqueeIsActive: true,
        shouldShowBottomBorder: false,
      });
    }

    window.addEventListener('mousedown', this.handleClickOutsideOfMenu, false);
    window.addEventListener('resize', this.throttleHandleViewPaddingTop);
    window.addEventListener('scroll', this.throttleHandleScrollTop);
  }

  componentWillUnmount() {
    window.removeEventListener('mousedown', this.handleClickOutsideOfMenu, false);
    window.removeEventListener('resize', this.throttleHandleViewPaddingTop);
    window.removeEventListener('scroll', this.throttleHandleScrollTop);
  }

  intializeApplication = () => {
    return ApiClient.fetchGlobalSettings().then((response: GlobalSettings) =>
      this.setState((state: State) => ({
        initializeApplicationFulfilled: true,
        globalSettings: response,
        hasSticker: !!response.notificationBar?.image?.src,
        notificationBarIsHidden: !!response.notificationBar?.disableNotificationBar,
      }))
    );
  };

  //If the menu is active and the user clicks outside of the menu, close the menu.
  handleClickOutsideOfMenu = (event: MouseEvent) => {
    //User clicked inside menu
    if (
      this.navContainerRef.current &&
      this.navContainerRef.current.contains(event.target as Node)
    ) {
      return;
    }

    if (this.state.mainMenuIsActive) {
      this.setState({
        mainMenuIsActive: false,
      });
    }
  };

  hideNotificationBar = () => {
    this.setState({
      notificationMarqueeIsActive: false,
      notificationBannerIsActive: false,
      notificationBarIsHidden: true,
    });
  };

  handleMainMenuClick = () => {
    if (this.state.mainMenuIsActive) {
      this.setState({
        mainMenuIsActive: !this.state.mainMenuIsActive,
      });
    }

    return null;
  };

  toggleMainMenu = () => {
    this.setState({
      mainMenuIsActive: !this.state.mainMenuIsActive,
    });
  };

  handleScroll = () => {
    const pageOffset =
      get(window, 'pageYOffset', 0) || get(document, 'documentElement.scrollTop', 0);

    if (pageOffset === 0 && this.state.mainMenuIsActive) {
      this.calculateViewPaddingTop();
    }

    if (pageOffset === 0 && this.state.shouldShowBottomBorder) {
      this.setState({
        shouldShowBottomBorder: false,
      });
    }

    if (pageOffset > 0 && !this.state.shouldShowBottomBorder) {
      this.setState({
        shouldShowBottomBorder: true,
      });
    }

    if (pageOffset === 0 && !this.state.secondaryNavIsActive) {
      this.setState({
        notificationMarqueeIsActive: true,
        notificationBannerIsActive: true,
        secondaryNavIsActive: true,
      });
    }

    if (pageOffset > 0 && this.state.secondaryNavIsActive) {
      this.setState({
        secondaryNavIsActive: false,
      });
    }

    if (pageOffset > 0 && !this.state.notificationMarqueeIsActive) {
      this.setState({
        notificationMarqueeIsActive: true,
      });
    }

    if (pageOffset > 0 && this.state.notificationBannerIsActive) {
      this.setState({
        notificationBannerIsActive: false,
      });
    }
  };

  throttleHandleScrollTop = throttle(this.handleScroll, 150);

  calculateViewPaddingTop = () => {
    const breakpointIsMdDown = [
      Breakpoints.MEDIUM.label,
      Breakpoints.SMALL.label,
      Breakpoints.SMALL_EXTRA_SMALL.label,
      Breakpoints.EXTRA_SMALL.label,
    ].includes(this.props.currentBreakpoint);

    const mainMenuHeight = get(document.getElementsByClassName('main-menu'), '[0].clientHeight', 0);

    const primaryNavHeight = get(
      document.getElementsByClassName('primary-nav'),
      '[0].clientHeight',
      0
    );

    const secondaryNavHeight = get(
      document.getElementsByClassName('secondary-nav'),
      '[0].clientHeight',
      0
    );

    let notificationBarHeight = get(
      document.getElementsByClassName('notification-marquee'),
      '[0].clientHeight',
      0
    );

    //If the notification banner is active, this adds the height of the notification banner in pixels, which is fixed.
    if (
      !this.state.notificationBarIsHidden &&
      this.state.notificationBannerIsActive &&
      this.state.hasSticker &&
      !breakpointIsMdDown
    ) {
      notificationBarHeight = notificationBarHeight + 249;
    }

    const navNotificationBarHeight =
      primaryNavHeight + secondaryNavHeight + mainMenuHeight + notificationBarHeight + 1;

    if (this.state.mainMenuIsActive) {
      if (this.state.navNotificationBarHeight !== navNotificationBarHeight) {
        this.setState({
          navNotificationBarHeight: navNotificationBarHeight,
        });
      }
    }
  };

  handleViewPaddingTop = () => {
    //The view's padding top should only update if the main menu is open.
    if (this.state.mainMenuIsActive) {
      this.calculateViewPaddingTop();

      return this.state.navNotificationBarHeight;
    }
  };

  throttleHandleViewPaddingTop = throttle(this.handleViewPaddingTop, 150);

  cookieConsentContent = (globalSettings: GlobalSettings) => {
    const text = globalSettings?.cookieConsent?.text;

    if (!text) {
      return null;
    }

    return <div className="flex flex-col">{text}</div>;
  };

  cookieConsentButton = (globalSettings: GlobalSettings) => {
    const link = globalSettings?.cookieConsent?.link;

    if (!link) {
      return null;
    }

    return (
      <Button
        className="text-decoration-none color-bronze inline link--style-hover-black"
        ariaLabel={Language.t('Global.readMore')}
        label={link.linkLabel}
        to={link.url}
      />
    );
  };

  render() {
    const {
      secondaryNavIsActive,
      notificationBannerIsActive,
      notificationMarqueeIsActive,
      notificationBarIsHidden,
      mainMenuIsActive,
      globalSettings,
      initializeApplicationFulfilled,
      shouldShowBottomBorder,
      hasSticker,
    } = this.state;

    const hasSecondaryNavActiveStyling = secondaryNavIsActive && !notificationBarIsHidden;

    const notificationBarSecondaryNavInactive =
      notificationBarIsHidden && !secondaryNavIsActive && !mainMenuIsActive;

    if (!initializeApplicationFulfilled) {
      return (
        <div className="Loader bg-color-white vw100 vh100">
          <Loader className="Loader__svg" />
        </div>
      );
    }

    return (
      <main>
        <div className="App">
          <Router>
            <ScrollToTop />
            {globalSettings && 'cookieConsent' in globalSettings && (
              <CookieConsent
                content={this.cookieConsentContent(globalSettings)}
                containerClassName="bg-color-white text-left text-meta-xs border-black"
                button={this.cookieConsentButton(globalSettings)}
                dismissButtonAriaLabel={Language.t('CookieConsent.acceptButton.ariaLabel')}
                dismissButtonClassName="bg-color-transparent text-meta-xs text-left color-bronze link--style-hover-black"
                dismissButtonLabel={Language.t('CookieConsent.acceptButton.label')}
              />
            )}
            <nav ref={this.navContainerRef}>
              <Nav
                toggleNotificationBar={this.hideNotificationBar}
                handleMainMenuClick={this.handleMainMenuClick}
                toggleMainMenu={this.toggleMainMenu}
                notificationBannerIsActive={notificationBannerIsActive}
                notificationMarqueeIsActive={notificationMarqueeIsActive}
                notificationBarIsHidden={notificationBarIsHidden}
                mainMenuIsActive={mainMenuIsActive}
                secondaryNavIsActive={secondaryNavIsActive}
                globalSettings={globalSettings}
                shouldShowBottomBorder={shouldShowBottomBorder}
                hasSticker={hasSticker}
              />
            </nav>
            <div
              style={{
                paddingTop: this.handleViewPaddingTop(),
              }}
              className={cx('view site-padding-x site-padding-y transition-medium flex flex-col', {
                'view--notification-bar-secondary-nav-active':
                  hasSecondaryNavActiveStyling && hasSticker,
                'view--notification-bar-active-no-sticker-secondary-nav-active':
                  hasSecondaryNavActiveStyling && !hasSticker,
                'view--notification-bar-secondary-nav-inactive':
                  notificationBarSecondaryNavInactive,
                'view--notification-bar-inactive':
                  notificationBarIsHidden && !notificationBarSecondaryNavInactive,
                'view--notification-bar-inactive-main-menu-active':
                  mainMenuIsActive && notificationBarIsHidden,
                'view--primary-nav-active-notification-marquee-active':
                  !secondaryNavIsActive && notificationMarqueeIsActive,
              })}
            >
              <Routes />
            </div>
          </Router>
        </div>
      </main>
    );
  }
}

export default withBreakpoints<Props>(App);
