import { RouteComponentProps } from 'react-router-dom';

import ApiClient from 'lib/ApiClient';
import ContainerBase from 'lib/ContainerBase';
import ArticleView from 'views/ArticleView';
import { ArticleResponse } from 'types';

interface StateProps {
  model: ArticleResponse | null;
}

type Props = RouteComponentProps & StateProps;

class ArticleContainer extends ContainerBase<Props, typeof ArticleView> {
  model = (): any => {
    const { pathname } = window.location;
    
    return ApiClient.fetchArticle(`/${pathname.slice(1).split('/')[1]}`);
  };

  view = import('views/ArticleView');
}

export default ArticleContainer;
