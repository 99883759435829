import Sanity from '@sanity/client';
import hashify from 'object-hash';

const ProjectId = '9t101670';
export type SanityDataset = 'production';

const dataset: SanityDataset = 'production';

const SanityClient = Sanity({
  projectId: ProjectId,
  dataset,
  useCdn: true
});

const SanityPreviewClient = Sanity({
  projectId: ProjectId,
  dataset,
  token: process.env.REACT_APP_SANITY_PREVIEW_TOKEN as string,
  useCdn: false,
});

class Client {
  private cache: { [key: string]: any };
  private SanityClient: typeof Sanity;

  constructor(client: typeof Sanity) {
    this.SanityClient = client;

    this.cache = {};
  }

  async getDocuments(ids: string[]) {
    return new Promise((resolve, reject) => {
      const hash: string = hashify(ids);

      if (!!this.cache[hash]) return resolve(this.cache[hash]);

      return this.SanityClient.getDocuments(ids)

        .then((response: unknown) => {
          this.cache[hash] = response;

          return resolve(response);
        })

        .catch(reject);
    });
  }

  async getDocument(id: string) {
    return new Promise((resolve, reject) => {
      const hash: string = hashify(id);

      if (!!this.cache[hash]) return resolve(this.cache[hash]);

      return this.SanityClient.getDocument(id)

        .then((response: unknown) => {
          this.cache[hash] = response;

          return resolve(response);
        })

        .catch(reject);
    });
  }

  async fetch(query: string) {
    return new Promise((resolve, reject) => {
      const hash: string = hashify(query);

      if (!!this.cache[hash]) return resolve(this.cache[hash]);

      return this.SanityClient.fetch(query)

        .then((response: unknown) => {
          this.cache[hash] = response;

          return resolve(response);
        })

        .catch(reject);
    });
  }
}

export const PreviewClient = new Client(SanityPreviewClient);
export default new Client(SanityClient);
